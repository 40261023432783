import React from "react";
import styled from "styled-components";

const AnnouncementBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #465a3b;
  color: #000;
  padding: 2rem;
  p {
    margin-bottom: 0;
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;
    @media (max-width: 767px) {
      text-align: left;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: baseline;
  }
`;

const Announcement = ({ children }) => {
  return <AnnouncementBanner>{children}</AnnouncementBanner>;
};

export default Announcement;
