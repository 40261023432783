import React from "react";
import Hero from "../components/Hero/Hero";
import Banner from "../components/HomeBanner/HomeBanner";
import ButtonLink from "../components/ButtonLink/ButtonLink";
import Navbar from "../components/Navbar/Navbar";
import TabbedServices from "../components/TabbedServices/TabbedServices";
import Footer from "../components/Footer/Footer";
import Review from "../components/LeaveAReview/Review";
import Announcement from "../components/Announcement/Announcement";

export default function HomePage() {
  const links = [
    {
      name: "Home",
      to: "/",
      active: true,
    },
    {
      name: "About Us",
      to: "/about-us",
      active: false,
    },
    {
      name: "Contact Us",
      to: "/contact-us",
      active: false,
    },
  ];

  return (
    <>
      <Navbar navLinks={links} />
      <div className="home-layout">
      <Announcement>
          <p>
            I will be away January 18th to January 28th. For emergencies please call
            Fisher Mills Animal Hospital at{" "}
            <a style={{ color: "gray" }} href="tel:5192602900">
              (519) 260-2900
            </a>{" "}
            or Campus Estates Animal Hospital at{" "}
            <a style={{ color: "gray" }} href="tel:5192602900">
              (519) 837-1212
            </a>
          </p>
        </Announcement>
        <main className="container">
          <Hero>
            <Banner
              title="Your pet is in good hands!"
              subtitle="Our mission is to provide unparalleled compassionate service at competitive prices. We are deeply committed to prioritizing the health and well-being of our clients' beloved pets, with the utmost seriousness. Our team wholeheartedly dedicates to ensure every service we provide receives unwavering dedication and effort, striving for excellence in every aspect of our work."
            >
              <ButtonLink link="/about-us" callToAction="See Our Story" />
            </Banner>
          </Hero>
          <TabbedServices />
          <Review />
        </main>
        <Footer />
      </div>
    </>
  );
}
