import React from "react";
import Navbar from "../components/Navbar/Navbar";
import ContactUsForm from "../components/ContactUsForm/ContactUsForm";
import Footer from "../components/Footer/Footer";
import Announcement from "../components/Announcement/Announcement";

export default function AboutUsPage() {
  const links = [
    {
      name: "Home",
      to: "/",
      active: false,
    },
    {
      name: "About Us",
      to: "/about-us",
      active: false,
    },
    {
      name: "Contact Us",
      to: "/contact-us",
      active: true,
    },
  ];

  return (
    <>
      <Navbar navLinks={links} />
      <div className="contact-us-layout">
      <Announcement>
          <p>
            I will be away January 18th to January 28th. For emergencies please call
            Fisher Mills Animal Hospital at{" "}
            <a style={{ color: "gray" }} href="tel:5192602900">
              (519) 260-2900
            </a>{" "}
            or Campus Estates Animal Hospital at{" "}
            <a style={{ color: "gray" }} href="tel:5192602900">
              (519) 837-1212
            </a>
          </p>
        </Announcement>
        <main className="container">
          <ContactUsForm />
        </main>
        <Footer />
      </div>
    </>
  );
}
